import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import TwoColDisplay from "../../components/two-col-display"

const JoshPage = () => {
  const query = useStaticQuery(graphql`
    {
      allFile(filter: { name: { eq: "profile-josh" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)

  const node = query.allFile.edges[0].node
  const image = node.childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="Josh" />
      <div className="grid grid-cols-1 items-center justify-center w-full">
        <div className="pt-4 flex flex-grow justify-center items-center">
          <div className="max-w-4xl">
            <TwoColDisplay image={image}>
              <div className="text-center">
                <a href="https://instagram.com/joshrobinsontattoos">
                  <h2 className="font-rubik text-xl no-underline text-black">
                    @joshrobinsontattoos
                  </h2>
                </a>

                <div className="px-8 grid grid-cols-1 gap-4 py-4 text-xl">
                  <div>
                    I’m Josh and I’m a licensed ink slinger at Sterling Tattoo
                    Club. I enjoy art, live music and mopping tattoo shop
                    floors.
                  </div>
                  <div>
                    I love to paint flash, dig through old reference books for
                    inspiration, and answer phones. I’m happiest when I see
                    someone who loves their new tattoo!
                  </div>
                  <div>
                    I love all traditional tattoos. My favorite designs are
                    always bold, wild, and off the wall American traditional!
                  </div>
                  {/* <div>Availability W,TH,F,S,S</div> */}
                  {/* <div>Contact Josh on Instagram</div> */}
                </div>
              </div>
            </TwoColDisplay>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JoshPage
