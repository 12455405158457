import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const TwoColDisplay = ({ children, image }) => {
  console.log("image", image)
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4 md:gap-0 px-4 md:p-0">
      <div className="flex justify-center">
        <GatsbyImage image={image} alt="image" />
      </div>
      <div>{children}</div>
    </div>
  )
}

export default TwoColDisplay
